import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import Scrollbar from '../scrollbar';
import NavSection from './NavSection';

const NAV_WIDTH = 240;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export function Nav({ data = [], openNav, onCloseNav, header }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ mx: 1 }}>
        <Link underline="none">
          {header}
        </Link>
      </Box>
      <NavSection data={data} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  const paperProps = {
    sx: {
      width: NAV_WIDTH,
      bgcolor: '#001529',
    },
  }

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={paperProps}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={paperProps}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
