import { useState } from "react";
import PropTypes from 'prop-types';
import {
  Button, Toolbar, styled,
  Box, Stack, TextField,
  FormControl, InputLabel, Select, MenuItem,
} from "@mui/material";
import { LocalizationProvider, MobileDateTimePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import useResponsive from "../hooks/useResponsive";
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  height: 50,
  overflow: 'auto',
  display: 'flex',
  bottom: 0,
  justifyContent: 'space-between',
  padding: theme.spacing(1, 1, 0, 3),
  variant: 'dense',
}));

function SearchOption({ type = 'text', name, label, items, setQueryParams, params }) {
  const isDesktop = useResponsive('up', 'lg');
  const setting = {
    label,
    format: "YYYY-MM-DD HH:mm:ss",
    onChange: (v) => {
      setQueryParams({ [name]: v ? v.format("YYYY-MM-DD HH:mm:ss") : null })
    }
  }
  const mobileDateTimePicker = () => {
    return <MobileDateTimePicker
      {...params}
      ampm={false}
      slotProps={{
        textField: {
          size: 'small',
          inputProps: {
            style: {
              padding: '5px 10px',
              width: '160px'
            }
          }
        },
      }}
      {...setting}
    />
  }
  const desktopDateTimePicker = () => {
    return <DesktopDateTimePicker
      {...params}
      ampm={false}
      slotProps={{
        actionBar: {
          actions: ['clear', 'accept'],
        },
        textField: {
          size: 'small',
          inputProps: {
            style: {
              padding: '5px 5px',
              width: '160px',
            }
          },
        },
      }}
      {...setting}
    />
  }
  const createOption = () => {
    switch (type) {
      case 'date':
        return (
          <Box width={220}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {isDesktop ? desktopDateTimePicker() : mobileDateTimePicker()}
            </LocalizationProvider>
          </Box>
        );
      case 'select':
        return (
          <Box sx={{ minWidth: 110 }}>
            <FormControl fullWidth>
              <InputLabel
                size="small"
              >
                {label}
              </InputLabel>
              <Select
                {...params}
                size="small"
                value={(params && params.value) ?? ''}
                onChange={(event) => {
                  setQueryParams({ [name]: event.target.value })
                }}
                inputProps={{
                  sx: {
                    padding: 0.7
                  }
                }}
              >
                {[
                  { label: '请选择', value: '' },
                  ...items
                ].map(v => <MenuItem key={v.value} value={v.value}>{v.label}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
        );
      case 'text':
        return (
          <Box sx={{ width: 150 }}>
            <TextField
              inputProps={{
                style: {
                  padding: '5px 10px',
                }
              }}
              size="small"
              {...params}
              placeholder={label}
              onChange={(event) => {
                setQueryParams({ [name]: event.target.value })
              }}
            />
          </Box>

        );
      default:
        throw new Error(`不存在类型：${type}`);
    }
  }

  return createOption();
}

SearchOption.propTypes = {
  type: PropTypes.oneOf(['text', 'date', 'select']),
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array,
  setQueryParams: PropTypes.func,
  params: PropTypes.object,
};

SearchOption.defaultProps = {
  type: 'text',
}

export function StandardSearchGroup({ children, options = [], setVersion, setQueryParams }) {
  return (
    <StyledToolbar variant='dense'>
      <Stack
        onSubmit={(event) => {
          event.preventDefault();
          setVersion((v) => v + 1);
        }}
        component='form'
        direction='row'
        spacing={1}
      >
        {options.map(v => <SearchOption key={v.name} {...v} setQueryParams={setQueryParams} />)}
        <Button
          type="submit"
          style={{ maxWidth: '80px', maxHeight: '34px' }}
          variant="outlined">
          查 询
        </Button>
      </Stack>
      {children}
    </StyledToolbar>
  );
}
StandardSearchGroup.propTypes = {
  children: PropTypes.object,
  options: PropTypes.array,
  setVersion: PropTypes.func,
  setQueryParams: PropTypes.func,
};
