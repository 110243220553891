import { useState } from 'react';
import { Box, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from './Iconify';

function isEmpty(str) {
  return str === null || str.trim() === ""
}

export default function LoginForm({ submit }) {
  const [username, setUsername] = useState();
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [googleAuthCode, setGoogleAuthCode] = useState();

  const handleSubmit = async () => {
    if (isEmpty(username)) {
      setUsernameError(true);
    }
    if (isEmpty(password)) {
      setPasswordError(true);
    }
    if (isEmpty(password) || isEmpty(username)) {
      return;
    }
    submit({
      username,
      password,
      googleAuthCode
    })
  };

  return (
    <>
      <Stack component='form' spacing={3}>
        <Box
          component="img"
          src="LoginLogo.svg"
          sx={{ height: 140, cursor: 'pointer' }}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
        <TextField
          required
          name="username"
          label="用户名"
          size='small'
          error={Boolean(usernameError)}
          onChange={(event) => {
            setUsername(event.target.value);
          }}
          onClick={() => setUsernameError(false)}
        />
        <TextField
          required
          name="password"
          label="密码"
          size='small'
          error={Boolean(passwordError)}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          onClick={() => setPasswordError(false)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          required
          name="code"
          label="谷歌验证码"
          size='small'
          onChange={(event) => {
            setGoogleAuthCode(event.target.value);
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
      <LoadingButton fullWidth size="medium" type="submit" variant="contained" onClick={handleSubmit}>
        登录
      </LoadingButton>
    </>
  );
}
