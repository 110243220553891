import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  styled, alpha, Box, LinearProgress
} from "@mui/material";
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import Footer from "./Footer";

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
          theme.palette.action.selectedOpacity +
          theme.palette.action.hoverOpacity,
        ),
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const COLUMN_CONFIG = { align: 'center', headerAlign: 'center', sortable: false, disableColumnMenu: true };
function configColumn(v) {
  const col = {
    ...COLUMN_CONFIG,
    ...v,
  };
  if (!v.width) {
    col.flex = 1;
  }
  return col;
}



// 列表组件
export function StandardTable({ columns, getRowId, data, query, queryParams, version, summary, notFoundSrc, rowHeight = 30, pageDisable = false, ...dataGridProps }) {
  // 分页
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 60,
  });
  const [rows, setRows] = useState([]);
  // 总数
  const [rowCount, setRowCount] = useState(0);
  // 进度条
  const [progress, setProgress] = useState(false);

  // TODO: 分页模型 
  // {
  //   page: 1,
  //   pageSize: 60,
  //   rows: [],
  //   rowCount,
  // }
  //
  const handleSearch = () => {
    async function fetchData() {
      const result = await query({
        ...queryParams,
        pageNum: pagination.page,
        numPerPage: pagination.pageSize,
      });
      setProgress(false);
      if (result && result.list) {
        setRows(result.list);
        if (result.pagination.total !== rowCount) {
          setRowCount(result.pagination.total);
        }
      }
    }
    setProgress(true);
    fetchData();
  };

  useEffect(handleSearch, [pagination.page, pagination.pageSize, version]);

  // 空数据背景
  const noRowsOverlay = () => (
    <Box
      sx={{
        height: 'calc(100vh - 220px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      align='center'>
      <Box
        sx={{ opacity: 0.03 }}
        height={400}
        width={400}
        component='img'
        src={notFoundSrc}
        alt="no item"
      />
    </Box>
  );

  const footer = () => <Footer
    rowCount={rowCount}
    pagination={pagination}
    setPagination={setPagination}
    summary={summary}
    pageDisable={pageDisable}
  />

  return (
    <Box sx={{
      height: 'calc(100vh - 120px)',
      /* 占满屏幕高度 */
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    }}>
      <StripedDataGrid
        {...dataGridProps}
        sx={{
          minWidth: 600,
        }}
        pagination
        loading={progress}
        getRowId={getRowId}
        columnHeaderHeight={35}
        rowHeight={rowHeight}
        rows={data ?? rows}
        rowCount={rowCount}
        paginationMode="server"
        columns={columns.map(configColumn)}
        getRowClassName={(params) => {
          if (dataGridProps.getRowClassName) {
            const cn = dataGridProps.getRowClassName(params);
            if (cn) {
              return cn;
            }
          }
          return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }}
        slots={{
          noRowsOverlay,
          footer,
          loadingOverlay: LinearProgress,
        }}
      />
    </Box>
  );
}
StandardTable.propTypes = {
  columns: PropTypes.array,
  getRowId: PropTypes.func,
  query: PropTypes.func,
  queryParams: PropTypes.object,
  version: PropTypes.number,
  summary: PropTypes.array,
};