import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';

const steps = ['验证', '设置', '完成'];

export default function GoogleAuthStepper({ verify, confirm, reset, open, onClose, initStep }) {
  const [activeStep, setActiveStep] = useState(initStep);
  const [oldCode, setOldCode] = useState();

  useEffect(() => setActiveStep(initStep), [initStep])

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleExit = () => {
    setActiveStep(0);
    onClose();
  }

  const stepOperation = (step) => {
    switch (step) {
      case 0:
        return <InputOldCode
          onClose={handleExit}
          verify={verify}
          onConfirm={(v) => {
            setOldCode(v)
            handleNext();
          }}
        />;
      case 1:
        return <ResetNewCode
          reset={reset}
          confirm={confirm}
          oldCode={oldCode}
          onClose={handleExit}
          onConfirm={handleNext} />;
      default:
        return <Success onClose={handleExit} />;
    }
  }

  return (
    <Dialog open={open}>
      <Grid sx={{ margin: 3 }}>
        <Stepper sx={{ width: '100%' }} activeStep={activeStep}>
          {steps.map((label, index) => {

            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Grid container direction='column' sx={{
          minHeight: 233,
          minWidth: 350,
        }}>
          {stepOperation(activeStep)}
        </Grid>
      </Grid>
    </Dialog>
  );
}
GoogleAuthStepper.propTypes = {
  verify: PropTypes.func,
  confirm: PropTypes.func,
  reset: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  initStep: PropTypes.number,
};

// 第一步，验证现有的谷歌验证码
function InputOldCode({ verify, onClose, onConfirm }) {
  const [code, setCode] = useState();
  const [error, setError] = useState(null);

  const handleSubmit = () => {
    if (!code) {
      setError('请输入谷歌验证码');
    } else {
      const apply = async () => {
        const result = await verify(code);
        if (result && result.retcode === 0) {
          onConfirm(code);
        } else {
          setError('谷歌验证错误');
        }
      }
      apply();
    }
  }

  return (
    <Grid container direction='column' >
      <Grid item>
        <DialogTitle color='red' align='center'>
          请将输入已绑定的谷歌验证码<br />
          点击【确定】后将为您提供新的二维码
        </DialogTitle>
      </Grid>
      <Grid item>
        <DialogContent >
          <TextField
            size='small'
            required
            autoFocus
            fullWidth
            error={error}
            margin="dense"
            label={error || "谷歌验证码"}
            type="number"
            onFocus={() => setError(null)}
            onChange={(event) => {
              setCode(event.target.value);
            }}
          />
        </DialogContent>
      </Grid>
      <Grid item>
        <DialogActions>
          <Button onClick={onClose} autoFocus>取消</Button>
          <Button onClick={handleSubmit} autoFocus>确认</Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
}

// 第二步，扫描心的谷歌验证码
function ResetNewCode({ reset, confirm, oldCode, onConfirm }) {
  const [code, setCode] = useState(null);
  const [error, setError] = useState(null);
  const [qrcodeURL, setQrcodeURL] = useState();

  const fetchImage = async () => {
    const res = await reset(oldCode);
    setQrcodeURL(URL.createObjectURL(await res.blob()));
  };

  useEffect(() => { fetchImage() }, []);

  // 重置
  const resetCode = async () => {
    const result = await confirm(code);
    if (result && result.retcode === 0) {
      onConfirm();
    } else {
      setError('绑定失败，请确认后重新输入');
    }
  };

  const handleSubmit = () => {
    if (!code) {
      setError(true)
    } else {
      resetCode();
    }
  }

  return (
    <>
      <DialogTitle color='red' align='center'>
        请扫描二维码图片并输入新的谷歌验证码
      </DialogTitle>
      <DialogContent >
        <Box
          component="img"
          sx={{ height: 350, width: 350, }}
          src={qrcodeURL}
        />
        <TextField
          required
          autoFocus
          fullWidth
          error={error}
          size='small'
          margin="dense"
          label={error || "请输入扫描后的谷歌验证码"}
          type="number"
          onFocus={() => setError(null)}
          onChange={(event) => {
            setCode(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled autoFocus>取消</Button>
        <Button onClick={handleSubmit} autoFocus>确认</Button>
      </DialogActions>
    </>
  );
}

// 第三步，显示成功
function Success({ onClose }) {
  return (
    <Grid
      direction="column"
      container
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid container justifyContent="space-around" alignItems="center" sx={{ mt: 4, mb: 1, height: 150 }}>
        <Typography fontSize={30} color='green'>设置成功</Typography>
      </Grid>
      <Grid>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button onClick={onClose}>结束</Button>
        </Box>
      </Grid>
    </Grid>
  );
}

