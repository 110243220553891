import { Navigate } from 'react-router-dom';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaidIcon from '@mui/icons-material/Paid';
import ReportIcon from '@mui/icons-material/Report';
import { Card } from '@mui/material';
import AppLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import LoginPage from './pages/login';
import Page404 from './pages/Page404';
import WithdrawPage from './pages/withdraw';
import { StatementPage, LogPage, WalletPage } from './pages/account';
import { CommissionPage, TotalAmountPage, ReconciliationPage } from './pages/report';

// 服务器api地址
export const serverURL = 'https://agentapi.doudoupays.com/agent';

// 登录页面
export const loginPath = `/login`;

// 主页
export const mainPath = `/`;


// 路由组件
export const navConfig = [
  {
    title: '账户',
    icon: <AccountBalanceIcon />,
    path: `/account`,
    items: [
      { title: '钱包', path: '/wallet', },
      { title: '帐变', path: '/statement', },
      { title: '日志', path: '/log', },
    ],
  },
  {
    title: '提款',
    icon: <PaidIcon />,
    path: `/withdraw`,
  },
  {
    title: '报表',
    icon: <ReportIcon />,
    path: `/report`,
    items: [
      { title: '总量', path: '/total', },
      { title: '佣金', path: '/commission', },
      { title: '对账', path: '/reconciliation', },
    ],
  },
];

function Element({ component }) {
  return (
    <Card sx={{ margin: 1 }}>
      {component}
    </Card>
  );
}

export const routesConfig = [
  {
    path: `${mainPath}`,
    element: <AppLayout />,
    children: [
      { element: <Navigate to={`/account/wallet`} />, index: true },
      { path: 'withdraw', element: <Element component={<WithdrawPage />} /> },
      { path: 'account/wallet', element: <Element component={<WalletPage />} /> },
      { path: 'account/statement', element: <Element component={<StatementPage />} /> },
      { path: 'account/log', element: <Element component={<LogPage />} /> },
      { path: 'report/total', element: <Element component={<TotalAmountPage />} /> },
      { path: 'report/commission', element: <Element component={<CommissionPage />} /> },
      { path: 'report/reconciliation', element: <Element component={<ReconciliationPage />} /> },
    ],
  },
  {
    path: `/login`,
    element: <LoginPage />,
  },
  {
    path: ``,
    element: <SimpleLayout />,
    children: [
      { element: <Navigate to={`/account/wallet`} />, index: true },
      { path: `/404`, element: <Page404 /> },
      { path: '*', element: <Navigate to={`/404`} /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to={`/404`} replace />,
  },
];
